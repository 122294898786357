<div class="body">
  <div class="header">
    <button (click)="toggleShowMenu()" class="menu small-device">
      <img src="assets/menu.svg">
    </button>
    <div class="logo">
      <div class="img-logo">
        <img src="assets/buzzbee.svg" width="100px">
      </div>
      <div class="text-logo">
        <strong>Buzz<span>Bee</span></strong>
        <p>The region’s no.1 influencer marketing platform. It’s time to get buzzin!</p>
      </div>
    </div>
      <div class="logo-label small-device">
        <p>The region’s no.1 influencer marketing platform. It’s time to get buzzin!</p>
      </div>
    
    <div class="nav" [ngClass]="{'show-menu': showMenu==true}">
      <ul>
        <li><a routerLink="/" (click)="toggleShowMenu()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a></li>
        <li><a routerLink="/features" (click)="toggleShowMenu()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Explore Features</a></li>
        <li><a routerLink="/influencer" (click)="toggleShowMenu()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Influencers</a></li>
        <li><a href="https://app.buzzbee.com">Client Login</a></li>
        <li class="start-btn"><a href="https://wa.me/971524965822" target="_blank">Start your campaign</a></li>
        <li class="demo-btn"><a href="https://meetings.hubspot.com/alma-miri" target="_blank">Book a demo</a></li>
      </ul>
      <button (click)="toggleShowMenu()" class="menu small-device">
        <img src="assets/close-menu.svg">
      </button>
    </div>
  </div>
  <div class="pages">
    <router-outlet></router-outlet>
  </div>
  <div class="influencer-app">
    <div class="data">
    <h3>Are you an influencer?</h3>
    <p>Passionate about creating content?<br>
    Download the BuzzBee App to become one of our influencer ‘bees’ and receive free experiences from restaurants, hotels, beauty salons, fitness and leisure facilities and many more services in your area.<br>
    <strong>Sign Up</strong>
    </p>
    <div>
    <a href="https://apps.apple.com/app/id1521231473" target="_blank"><img src="assets/ios.svg"></a>
    <a href="https://play.google.com/store/apps/details?id=com.buzzbee.influencers" target="_blank"><img src="assets/android.svg"></a>
    </div>
    </div>
  </div>
  <div class="footer">
    <div class="data">
      <div class="flex">
        <div class="col first">
          <div class="text-logo">
            <strong>Buzz<span>Bee</span></strong>
          </div>
          <p class="buzzin">Let’s Get Buzzin!</p>
          <p class="invite">Invite local influencers to experience your business and promote it</p>
          <div class="influencer small-device-hide"><strong>Are you an influencer?</strong></div>
          <div class="apps small-device-hide">
            <a href="https://apps.apple.com/app/id1521231473" target="_blank"><img src="assets/ios.svg"></a>
            <a href="https://play.google.com/store/apps/details?id=com.buzzbee.influencers" target="_blank"><img src="assets/android.svg"></a>
          </div>
        </div>
        <div class="col company">
          <div class="heading">Quick Links</div>
          <ul>
            <li>-&nbsp; <a routerLink="/"   routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</a></li>
            <li>-&nbsp; <a routerLink="/features"   routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Explore Features</a></li>
            <li>-&nbsp; <a routerLink="/influencer"   routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Influencers</a></li>
            <li>-&nbsp; <a href="https://app.buzzbee.com">Client Login</a></li>
            <li>-&nbsp; <a routerLink="/terms"  routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">Terms of use - Influencer</a></li>
            <li>-&nbsp; <a routerLink="/terms-business"   routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Terms of use - Client</a></li>
            <li>-&nbsp; <a routerLink="/privacy"  routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col contact">
          <div class="heading">Contact Us</div>
          <ul>
            <li><img src="assets/phone.svg" /> (+971) 52 496 5822</li>
            <li><img src="assets/mail.svg" /> support&#64;buzzbee.com</li>
            <li><img src="assets/address.svg" /> Dubai, UAE, Boulevard Plaza Tower 1, Sheikh Mohammed bin Rashid Blvd</li>
          </ul>
          <div class="social-icons">
            <a href="https://www.facebook.com/buzzbee.platform/" target="_blank"><img src="assets/fb.svg" /></a>
            <a href="https://www.linkedin.com/company/buzzbee-platform/" target="_blank"><img src="assets/in.svg" /></a>
            <a href="https://twitter.com/buzzbeeplatform" target="_blank"><img src="assets/tw.svg" /></a>
            <a href="https://www.instagram.com/buzzbee_fans/" target="_blank"><img src="assets/insta.svg" /></a>
          </div>
        </div>
        <div class="col newsletter">
          <div class="heading">Newsletter</div>
          <p>Sign up and recieve the latest tips via email.</p>
          <div class="enter-email">Enter your email</div>
            <form [formGroup]="formData" (ngSubmit)="onSubmit(formData?.value)">
              <div style="padding-top:8px;">
              <input matInput type="email" placeholder="name@domain.com" autocomplete="off" formControlName="email">
            </div>
            <mat-spinner [diameter]="20" class="spinner" *ngIf="isSending" ></mat-spinner>
            <button mat-raised-button color="primary" *ngIf="!isSending" [disabled]="formData?.invalid" style="margin-right:4px">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="data">
        <div class="flex">
          <div class="text">
            &copy; {{year}} BuzzBee by <a href="https://www.brandripplr.com" target="_blank"><strong>Brandripplr</strong></a>
          </div>
          <div class="creditcard">
            <img src="assets/visa.svg">
            <img src="assets/mastercard.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-cookies-consent></app-cookies-consent>
<a href="https://wa.me/971524965822" target="_blank" class="whatsapp"><img src="assets/whatsapp.png"></a>